import { Injectable, inject } from '@angular/core'
import { KeysService } from './keys.service'
import { LogService } from './log.service'
import { environment } from 'src/environments/environment'

declare global {
  interface Window {
    heap: any
  }
}

@Injectable()
export class HeapService {
  private _logger: LogService = inject(LogService)
  private keysService: KeysService = inject(KeysService)
  window = inject(Window)

  init() {
    this._logger.debug('HeapService.init()')
    this.loadHeapAnalytics()
  }

  private loadHeapAnalytics() {
    this._logger.debug('HeapService.loadHeapAnalytics()')
    const keys = this.keysService.keys()
    const heapKey = environment.heap.appId

    if (!this.window?.heap && !(typeof window?.heap?.load === 'function')) { return }

    this.window.heap.load(heapKey)
    this._logger.debug('HeapService.loadHeapAnalytics() - Heap.io loaded with key: ', heapKey)

    if (!keys.heap_key) {
      this._logger.debug('HeapService.loadHeapAnalytics() - No key found')
      return
    }


    if (this.window.heap.loaded) { return }

    this.window.heap.load(keys.heap_key)
  }
}
